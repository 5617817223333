<template>
  <b-card id="scrollable_div" style="width:100%; margin-right:auto;margin-left:auto;max-height:60vh;overflow:scroll;scroll-behavior: smooth; margin-bottom:1rem;">
    <div v-if="!ready" class="text-center">
      <b-spinner type="grow"></b-spinner>
    </div>
    <app-timeline
      v-else
    >
      <app-timeline-item
        v-for="log in logs"
        :key="log.id"
        variant="warning"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <p>{{ log.action }}</p>
          <small class="text-muted">Il y a {{ timeSince(log.date*1000) }}</small>
        </div>
        <b-media>
          <b-link class="alert-link" :to="{ name: 'admin-view', params: { id: `${log.userid}` }}">
            <h6>{{ log.who }}</h6>
          </b-link>
        </b-media>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BCard, BMedia, BLink, BSpinner,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BMedia,
    BLink,
    BSpinner,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      page_message: 1,
      logs: [],
      ready: false,
    }
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/logs/'+this.page_message)
      .then(response => {
        this.logs = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
      this.$nextTick(function () {
        document.getElementById("scrollable_div").addEventListener('scroll', this.handleScroll);
      })
  },
  destroyed () {
    document.getElementById("scrollable_div").removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    timeSince(date) {
      const seconds = Math.floor((new Date() - date) / 1000)

      let interval = seconds / 31536000

      if (interval > 1) {
        return `${Math.floor(interval)} an(s)`
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return `${Math.floor(interval)} mois`
      }
      interval = seconds / 86400
      if (interval > 1) {
        return `${Math.floor(interval)} jour(s)`
      }
      interval = seconds / 3600
      if (interval > 1) {
        return `${Math.floor(interval)} heure(s)`
      }
      interval = seconds / 60
      if (interval > 1) {
        return `${Math.floor(interval)} minute(s)`
      }
      return `${Math.floor(interval)} seconde(s)`
    },
    scroll() {
      var objDiv = document.getElementById("scrollable_div");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    handleScroll(event) {
      if(event.target.scrollTop == event.target.scrollHeight - event.target.offsetHeight) {
        this.ready = false
        this.page_message++;
        axios
          .get('https://app2.oscar-black.com/api/logs/'+this.page_message)
          .then(response => {
            this.logs = response.data
            this.ready = true
          })
          .catch(() => {})
      }
    },
  },
}
</script>

<style>

</style>
